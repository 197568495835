import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import Carousel, { Modal, ModalGateway } from 'react-images'
import Equalizer from '../components/Equalizer'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/PageHeader'

class InfographicsReportsPage extends React.PureComponent {
  state = {
    isImageModalOpen: false,
    imageModalImage: '',
  }

  getNodesForEqualizer(nodePrefix) {
    return Object.getOwnPropertyNames(this)
      .filter(property => property.indexOf(nodePrefix) === 0)
      .map(property => this[property])
  }

  downloadFile(e, media_file) {
    e.preventDefault();

    fetch(media_file.url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
    .then(res => res.blob())
    .then(res => {
      const aElement = document.createElement('a');
      aElement.setAttribute('download', media_file.name);
      const href = URL.createObjectURL(res);
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
    });
  }

  render() {
    const { pageContext } = this.props

    const { infographics, infographicsReportsPage: page } = pageContext

    const { isImageModalOpen, imageModalImage } = this.state

    const images = infographics.filter(
      ({ media_file }) => !media_file.url.match(/\.pdf$/)
    )
    const pdfs = infographics.filter(({ media_file }) =>
      media_file.url.match(/\.pdf$/)
    )

    return (
      <Layout>
        <SEO title={page.title} keywords={[`longevity`]} />

        <PageHeader titles={[page.title]} richDescription={page.description} />

        {pdfs.length > 0 && (
          <>
            <div className="container mx-auto lg:max-w-xl px-8 lg:px-0 mt-16">
              <h2 className="font-serif text-blue-dianne leading-tight text-xl font-normal">
                Reports
              </h2>
            </div>

            <div className="container lg:max-w-xl px-8 lg:px-0 mx-auto mb-24 lg:mb-32 font-serif text-blue-dianne leading-tight">
              {/* Hack for IE11 with wrong flexbox item height calculations */}
              <Equalizer
                byRow={false}
                nodes={() => this.getNodesForEqualizer('node')}
                childNodeGetter={node => node.querySelector('img')}
                enabled={() => (this.media ? this.media.matches : true)}
                forceGroupItemsCount
                groupItemsCount={1}
              >
                <Equalizer
                  byRow={false}
                  nodes={() => this.getNodesForEqualizer('reportTitleNode')}
                  enabled={() => (this.media ? this.media.matches : true)}
                  forceGroupItemsCount
                  groupItemsCount={3}
                >
                  <div className="lg:flex flex-wrap lg:-mx-4">
                    {pdfs.map(
                      (
                        {
                          title,
                          publication_date,
                          publication_date_format,
                          source_link,
                          source_name,
                          thumbnail,
                          file,
                          media_file,
                        },
                        index
                      ) => (
                        <div
                          className="flex flex-col lg:w-1/3 lg:px-4 mt-16"
                          key={index}
                        >
                          <div
                            className="order-2 lg:order-1"
                            ref={node => (this[`node${index}`] = node)}
                          >
                            {media_file._linkType === 'Link.image' && (
                              <button
                                className="block w-full"
                                onClick={() =>
                                  this.setState({
                                    isImageModalOpen: true,
                                    imageModalImage: thumbnail.url,
                                  })
                                }
                              >
                                <img
                                  src={thumbnail.url}
                                  alt=""
                                  className="block w-full"
                                />
                              </button>
                            )}
                            {media_file._linkType === 'Link.file' && (
                              <a
                                className="block w-full no-underline"
                                href={media_file.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={thumbnail.url}
                                  alt=""
                                  className="block w-full"
                                />
                              </a>
                            )}
                          </div>
                          <h2
                            className="font-normal text-lg mb-4 lg:mb-0 lg:mt-4 order-1 lg:order-2"
                            ref={node =>
                              (this[`reportTitleNode${index}`] = node)
                            }
                          >
                            <a
                              href={media_file.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="no-underline text-blue-dianne"
                            >
                              {title}
                            </a>
                          </h2>
                          {source_link && (
                            <div className="mt-2 lg:mt-4 order-2 lg:order-3 flex items-center">
                              <span className="font-sans text-gothic">
                                Source:
                              </span>
                              <a
                                href={source_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-gothic font-sans lg:no-underline hover:underline truncate ml-1"
                              >
                                {source_name || source_link}
                              </a>
                            </div>
                          )}
                          <div className="mt-2 font-sans text-gothic text-sm order-4 lg:flex items-center">
                            <div>
                              {media_file && (
                                <a
                                onClick={e => this.downloadFile(e, media_file)}
                                  href="#"
                                  className="no-underline hover:underline text-gothic uppercase"
                                >
                                  Download PDF
                                </a>
                              )}
                              {publication_date && (
                                <>
                                  <span className="mx-4 text-gothic opacity-50">
                                    |
                                  </span>
                                  <span className="text-gothic">
                                    {publication_date_format === 'YYYY'
                                      ? 'Published '
                                      : ''}
                                    {moment(
                                      publication_date,
                                      'YYYY-MM-DD'
                                    ).format(publication_date_format || 'YYYY')}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </Equalizer>
              </Equalizer>
            </div>
          </>
        )}

        {/* --------------------------------------------------------- */}

        {images.length > 0 && (
          <>
            <div className="container mx-auto lg:max-w-xl px-8 lg:px-0 mt-16">
              <h2 className="font-serif text-blue-dianne leading-tight text-xl font-normal">
                Infographics
              </h2>
            </div>

            <div className="container lg:max-w-xl px-8 lg:px-0 mx-auto mb-24 lg:mb-32 font-serif text-blue-dianne leading-tight">
              {/* Hack for IE11 with wrong flexbox item height calculations */}
              <Equalizer
                byRow={false}
                nodes={() => this.getNodesForEqualizer('node')}
                childNodeGetter={node => node.querySelector('img')}
                enabled={() => (this.media ? this.media.matches : true)}
                forceGroupItemsCount
                groupItemsCount={1}
              >
                <Equalizer
                  byRow={false}
                  nodes={() =>
                    this.getNodesForEqualizer('infographicTitleNode')
                  }
                  enabled={() => (this.media ? this.media.matches : true)}
                  forceGroupItemsCount
                  groupItemsCount={3}
                >
                  <div className="lg:flex flex-wrap lg:-mx-4">
                    {images.map(
                      (
                        { title, related_article, thumbnail, file, media_file },
                        index
                      ) => (
                        <div
                          className="flex flex-col lg:w-1/3 lg:px-4 mt-16"
                          key={index}
                        >
                          <div
                            className="order-2 lg:order-1"
                            ref={node => (this[`node${index}`] = node)}
                          >
                            {media_file._linkType === 'Link.image' && (
                              <button
                                className="block w-full"
                                onClick={() =>
                                  this.setState({
                                    isImageModalOpen: true,
                                    imageModalImage: thumbnail.url,
                                  })
                                }
                              >
                                <img
                                  src={thumbnail.url}
                                  alt=""
                                  className="block w-full"
                                />
                              </button>
                            )}
                            {media_file._linkType === 'Link.file' && (
                              <a
                                className="block w-full no-underline"
                                onClick={e => this.downloadFile(e, media_file)}
                                href="#"
                              >
                                <img
                                  src={thumbnail.url}
                                  alt=""
                                  className="block w-full"
                                />
                              </a>
                            )}
                          </div>
                          <h2
                            className="font-normal text-lg mb-4 lg:mb-0 lg:mt-4 order-1 lg:order-2"
                            ref={node =>
                              (this[`infographicTitleNode${index}`] = node)
                            }
                          >
                            <a
                                onClick={e => this.downloadFile(e, media_file)}
                                href="#"
                                className="no-underline text-blue-dianne"
                            >
                              {title}
                            </a>
                          </h2>
                          <p className="mt-4 font-sans text-gothic text-sm order-3">
                            {media_file && (
                              <a
                              onClick={e => this.downloadFile(e, media_file)}
                              href="#"
                              className="no-underline hover:underline text-gothic uppercase"
                              >
                                Download
                              </a>
                            )}
                            {related_article && (
                              <>
                                <span className="mx-4 text-gothic opacity-50">
                                  |
                                </span>
                                <Link
                                  to={`/${
                                    related_article._meta.type === 'article'
                                      ? 'insight'
                                      : 'podcast'
                                  }/${related_article._meta.uid}`}
                                  className="no-underline hover:underline text-gothic uppercase"
                                >
                                  Read related article
                                </Link>
                              </>
                            )}
                          </p>
                        </div>
                      )
                    )}
                  </div>
                </Equalizer>
              </Equalizer>
            </div>
          </>
        )}

        <ModalGateway>
          {isImageModalOpen && (
            <Modal
              onClose={() => this.setState({ isImageModalOpen: false })}
              allowFullscreen={false}
            >
              <Carousel
                views={[{ src: imageModalImage }]}
                components={{ Footer: null }}
              />
            </Modal>
          )}
        </ModalGateway>
      </Layout>
    )
  }
}

export default InfographicsReportsPage
